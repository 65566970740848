<template>
  <label class="multicheckbox hover:bg-gray-300" @mouseover="hover = true" @mouseleave="hover = false">
    <span class="text-base text-gray-700 font-bold">{{ label }}</span>
    <input v-model="checked" type="checkbox" @click.prevent="open" />
    <span class="checkmark" :class="checkClass" @click.prevent="clickCheckmark"></span>
    <sun-arrow-down-svg
      v-if="canOpen"
      class="absolute right-0 z-10"
      :class="{ 'rotate-180': opened }"
    ></sun-arrow-down-svg>
  </label>
</template>
<script>
export default {
  name: 'CheckboxSimple',
  props: {
    label: {
      type: String,
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    canOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.selected,
      hover: false,
      opened: true,
    };
  },
  computed: {
    checkClass() {
      return {
        'bg-orange-500': this.selected,
        'bg-gray-200': !this.selected,
        'hover:bg-orange-300': this.hover,
      };
    },
  },
  watch: {
    selected(newValue) {
      this.checked = newValue;
    },
  },
  methods: {
    clickCheckmark() {
      this.checked = !this.checked;
      this.$emit('change', {
        name: this.label,
        checked: this.checked,
      });
    },
    open() {
      this.opened = !this.opened;

      if (this.opened) this.$emit('open');
      else this.$emit('close');
    },
  },
};
</script>

<style scoped>
/* Customize the label (the multicheckbox) */
.multicheckbox {
  display: flex;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 24px;
  padding-top: 2px;
}

/* Hide the browser's default checkbox */
.multicheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 15px;
  width: 15px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.multicheckbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.multicheckbox .checkmark:after {
  left: 6px;
  top: 4px;
  width: 4px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
