var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "multicheckbox hover:bg-gray-300",
      on: {
        mouseover: function ($event) {
          _vm.hover = true
        },
        mouseleave: function ($event) {
          _vm.hover = false
        },
      },
    },
    [
      _c("span", { staticClass: "text-base text-gray-700 font-bold" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.checked,
            expression: "checked",
          },
        ],
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.checked)
            ? _vm._i(_vm.checked, null) > -1
            : _vm.checked,
        },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.open.apply(null, arguments)
          },
          change: function ($event) {
            var $$a = _vm.checked,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.checked = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.checked = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.checked = $$c
            }
          },
        },
      }),
      _c("span", {
        staticClass: "checkmark",
        class: _vm.checkClass,
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.clickCheckmark.apply(null, arguments)
          },
        },
      }),
      _vm.canOpen
        ? _c("sun-arrow-down-svg", {
            staticClass: "absolute right-0 z-10",
            class: { "rotate-180": _vm.opened },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }